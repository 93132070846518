<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <ion-button slot="icon-only" fill="clear" class="close-button" (click)="dismissModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="empty-state-container">
    <span class="placeholder-emoji">
      <ion-icon name="mail-outline"></ion-icon>
    </span>
    <h1 class="placeholder-title">Check your email</h1>
    <p>As a one-time step, we've sent an email to verify you.</p>
    <p class="placeholder-subtitle">Didn't get an email? <a (click)="contactSupport()">Let us know</a></p>
  </div>
</ion-content>
