import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-password-link',
  templateUrl: './password-link.page.html',
  styleUrls: ['./password-link.page.scss'],
})
export class PasswordLinkPage {
  constructor(public modalCtrl: ModalController) {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  contactSupport() {
    window.open(`mailto:support@unthread.io`);
  }
}
